<template>

      <v-img
        lazy-src="/images/map-v2.svg"
        height="200"
        width="100%"
        src="/images/map-v2.svg"
        position="top center"
      ></v-img>

</template>

<script>
  export default {}
</script>
