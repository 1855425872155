import envConfig from '../../environment.config';
import { Help } from '../types';

const axios = require('axios').default;

class HelpService
{
  async askForDruide(gameCode: String): Promise<boolean>
  {
    return await axios
      .post(envConfig.apiUrl + 'askForDruide', {
        gameCode: gameCode
      })
      .then(() => true)
      .catch(() => false);
  }

  async getNextDruideHelp(): Promise<Help>
  {
    return await axios
      .get(envConfig.apiUrl + 'nextDruideHelp')
      .then(response => response.data.demande)
      .catch(() => null)
  }
}

export default new HelpService();
