<template>

  <v-container fluid>

    <GameTopBar
      :game="game"
      :isDruide="isDruide"
      :gameState="gameState"
      v-on:game:start="startGame"
    ></GameTopBar>

    <GameTeamBar
      v-if="isDruide"
      :players="game.teamPlayers"
      :name="game.teamName"
      v-on:update:teamName="updateTeamName"
      v-on:update:teamPlayers="updateTeamPlayers"
    ></GameTeamBar>

    <Map></Map>

    <GameProgressBar :completed="progression"></GameProgressBar>

    <GameRiddles
      :gameId="game.id"
      :riddles="riddles"
      :is-druide="isDruide"
      v-on:response:correct="setCorrectResponse($event)"
    ></GameRiddles>

  </v-container>

</template>

<script>
  import GameTopBar from '../components/GameTopBar'
  import GameTeamBar from '../components/GameTeamBar'
  import GameRiddles from '../components/GameRiddles'
  import GameProgressBar from '../components/GameProgressBar'
  import Map from '../components/Map'
  import gameService from '../services/gameService'
  import riddleService from '../services/riddleService'
  import authService from '../services/authService'
  import { GameState } from "../enums"

  export default {
    data: () => ({
      game: {},
      riddles: [],
      progression: 0,
      isDruide: false,
    }),
    mounted() {
      this.setIsDruide()
      gameService.getGameByCode(this.$route.params.code).then(this.setGame)
    },
    watch: {
      $route() {
        gameService.getGameByCode(this.$route.params.code).then(this.setGame)
        this.setIsDruide()
      }
    },
    components: {
      GameTopBar,
      GameTeamBar,
      GameProgressBar,
      GameRiddles,
      Map
    },
    computed: {
      gameState() {
        if (this.game.completeDate != null) {
          return GameState.ENDED
        } else if (this.game.beginDate != null) {
          return GameState.STARTED
        } else {
          return GameState.STOPPED
        }
      }
    },
    methods: {
      setGame: function (game) {
        this.game = game
        riddleService.getRiddles(this.game.id, localStorage.getItem('token')).then(this.setRiddles)
      },
      setRiddles: function (riddles) {
        this.riddles = riddles
        this.updateProgression()
      },
      updateProgression: function () {
        let nbCompleted = 0
        this.riddles.forEach((item) => {
          if (item.completed) {
            ++nbCompleted
          }
        })
        this.progression = (nbCompleted / this.riddles.length * 100)
      },
      setIsDruide: function() {
        let userToken = localStorage.getItem('token');
        if (userToken !== null) {
          let self = this;
          authService.isDruide(userToken).then(isDruide => {
            self.isDruide = isDruide;
          });
        }
        else {
          this.isDruide = false;
        }
      },
      setCorrectResponse: function(event) {
        this.riddles.forEach((riddle) => {
          if (riddle.id === event.riddleId) {
            riddle.completed = true
            riddle.response = event.correctResponse
          }
        })
        this.updateProgression()
        if (this.progression >= 100) {
          const completeAt = new Date()
          gameService.stopGame(this.game.code, completeAt).then((isGameStopped) => {
            if (isGameStopped) {
              this.game.completeDate = completeAt.toString()
            }
          })
        }
      },
      updateTeamName: function(newName) {
        gameService.updateTeamName(this.game.code, newName)
      },
      updateTeamPlayers: function(newPlayers) {
        gameService.updateTeamPlayers(this.game.code, newPlayers)
      },
      startGame: function(startAt) {
        gameService.startGame(this.game.code, startAt)
      }
    }
  }
</script>
