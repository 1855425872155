<template>

  <v-container fluid>
    <v-toolbar dense flat>
      <v-btn icon to="/">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </v-toolbar>

    <div class="mb-4">
      <h2>Connexion</h2>
    </div>

    <p v-if="error" class="error">Erreur de connexion</p>

    <v-form ref="loginForm">
      <v-text-field
        v-model="login"
        label="Identifiant"
        placeholder="John Doe"
        name="username"
        :rules="[rules.required]"
        outlined
      ></v-text-field>
      <v-text-field
        v-model="password"
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.required]"
        :type="show ? 'text' : 'password'"
        name="password"
        label="Mot de passe"
        outlined
        @click:append="show = !show"
      ></v-text-field>
      <v-btn
        color="light"
        @click="validate"
      >
        Se connecter
      </v-btn>
    </v-form>
  </v-container>

</template>

<script>
  import authService from '../services/authService';

  export default {
    data: () => ({
      login: '',
      password: '',
      show: false,
      rules: {
        required: value => !!value || 'champ obligatoire.',
      },
      error: false,
    }),
    mounted() {
      let token = localStorage.getItem('token');
      if (token === null) {
        return;
      }
      let self = this;
      authService.isDruide(token).then(isDruide => {
        if (isDruide) {
          self.$router.push({name: 'scenario'});
        }
      })
    },
    methods: {
      validate() {
        if (this.$refs.loginForm.validate()) {
          let self = this;
          authService.login(this.login, this.password).then(token => {
            if (typeof token === 'undefined' || token === '') {
              self.error = true;
              return;
            }
            self.error = false;
            localStorage.setItem('token', token);
            this.$router.push({name: 'scenario'});
          });
        }
      },
    }
  }
</script>
