<template>
  <v-toolbar
    flat
    class="py-2 mt-2"
  >
    <v-text-field
      label="Nom d'équipe"
      v-model="teamName"
      v-on:change="$emit('update:teamName', teamName)"
    ></v-text-field>
    <v-select
      label="Nombre de joueurs"
      v-model="teamPlayers"
      :items="playersList"
      v-on:change="$emit('update:teamPlayers', teamPlayers)"
    ></v-select>
  </v-toolbar>
</template>

<script>
  import envConfig from '../../environment.config'

  export default {
    props: {
      players: Number,
      name: String,
    },
    data: () => ({
      playersList: [],
      teamPlayers: '',
      teamName: '',
    }),
    mounted() {
      for(let i = envConfig.players.min; i <= envConfig.players.max; ++i) {
        this.playersList.push(i)
      }
      this.teamPlayers = this.players
      this.teamName = this.name
    },
    watch: {
      name(newValue) {
        this.teamName = newValue
      },
      players(newValue) {
        this.teamPlayers = newValue
      }
    }
  }
</script>