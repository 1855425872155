<template>

  <v-card
    flat
    heigh="100%"
  >
    <v-card-text class="riddle-text" v-text="riddle.description"></v-card-text>

    <v-row justify="space-around">
      <v-col
        v-for="clue in riddle.clues"
        :key="clue.id"
      >
        <p v-if="clue.text">{{clue.text}}</p>
        <img
          v-else
          class="img-clue"
          :src="imagePath(clue.icon)"
          :alt="clue.icon"
        />
      </v-col>
    </v-row>
  </v-card>

</template>

<style scoped>
  .riddle-text {
    font-size: 1.3em;
  }
  .img-clue {
    width: 48px;
    height: 48px;
  }
</style>

<script>
  import { Riddle } from '../types';

  export default {
    props: {
      'riddle': Riddle
    },
    methods: {
      imagePath(icon) {
        return `/images/${icon}.jpg`
      }
    }
  }
</script>