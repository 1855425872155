<template>

  <v-bottom-navigation
    app
    fixed
    grow
    v-model="activeItem"
    color="primary"
    class="text-center"
  >
    <!-- Scenario button -->
    <v-btn value="scenario" :to="{ name: 'scenario' }" height="100%">
      <span>Scénario</span>
      <v-icon>book</v-icon>
    </v-btn>
    <!-- Game buttons -->
    <v-btn v-for="item in items" :key="item.code" :value="item.code" :to="{ path: '/game/'+item.code }" height="100%">
      <span>{{ item.teamName }}<br/>{{ item.code }}</span>
    </v-btn>
    <!-- New game button -->
    <v-btn
      icon
      v-bind:class="{ 'v-btn--disabled': isMaxGames }"
      @click="createGame"
      height="100%"
    >
      <v-icon>add</v-icon>
    </v-btn>
  </v-bottom-navigation>

</template>

<script>
  import gameService from '../services/gameService'
  import envConfig from '../../environment.config'

  export default {
    data: () => ({
      activeItem: '',
      items: []
    }),
    mounted() {
      this.setGames()
    },
    computed: {
      isMaxGames() {
        return this.items.length >= envConfig.maxsimultaneousGames
      }
    },
    methods: {
      setGames() {
        gameService.getGames().then(games => {
          this.items = games
        })
      },
      createGame() {
        gameService.createGame().then(isGameCreated => {
          if (isGameCreated) {
            this.setGames()
          }
        })
      }
    }
  }
</script>