<template>

  <div class="text-center">

    <v-text-field
      label="Réponse"
      v-model="response"
      :error="error"
      class="px-6"
    ></v-text-field>

    <v-btn color="info" @click="validResponse">
      Valider
    </v-btn>
  </div>

</template>

<script>
  import riddleService from "../services/riddleService";

  export default {
    props: {
      gameId: Number,
      riddleId: Number
    },
    data: () => ({
      response: '',
      error: false
    }),
    methods: {
      validResponse() {
        let self = this;
        riddleService.isCorrectResponse(this.gameId, this.riddleId, this.response).then(isResponseCorrect => {
          self.error = !isResponseCorrect;
          if (isResponseCorrect) {
            self.$messenger.showSuccessSnackbar('Bonne réponse !');
            self.$emit('response:correct', self.response);
          }
          self.response = '';
        });
      }
    }
  }
</script>