<template>
  <div>
    <v-app-bar
      app
      fixed
      dark
      dense
      color="primary"
    >
      <v-btn
        icon
        value=""
        :to="{ path: '/'}">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>

      <v-toolbar-title>{{ game.code }}</v-toolbar-title>
      
      <v-spacer></v-spacer>

      <timer
        v-if="isDruide"
        :beginDate="game.beginDate"
        :completeDate="game.completeDate"
        :gameState="gameState"
        v-on:game:start="$emit('game:start', $event)"
      ></timer>

    </v-app-bar>

  </div>
</template>

<script>
  import Timer from './GameTimer'
  import {Game} from '../types'

  export default {
    props: {
      game: Game,
      isDruide: Boolean,
      gameState: Number,
    },
    components: {
      Timer
    }
  }
</script>