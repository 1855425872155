<template>

  <div class="d-inline-flex">
    <v-btn
      small
      color="success"
      v-if="isGameStopped"
      @click="startGame"
    >Démarrer le jeu</v-btn>
    <v-btn v-if="isGameStarted" text disabled>Jeu en cours</v-btn>
    <v-btn v-if="isGameEnded" text color="error">Jeu terminé</v-btn>

    <div class="ml-2">
      <span v-if="hours > 0">{{ hoursString }}:</span>
      <span>{{ minutesString }}:</span>
      <span>{{ secondsString }}</span>
    </div>
  </div>

</template>

<script>
  import { GameState } from "../enums";

  export default {
    props: {
      beginDate: String,
      completeDate: String,
      gameState: Number,
    },
    data: () => ({
      hours: 0,
      minutes: 0,
      seconds: 0,
      refreshInterval: null,
    }),
    mounted() {
      if (this.beginDate != null) {
        const startAt = new Date(this.beginDate)
        const compareDate = this.completeDate
          ? new Date(this.completeDate)
          : new Date()

        const timeSpentInSeconds = (compareDate - startAt) / 1000
        this.hours = (Math.floor(timeSpentInSeconds / (60 * 60)) % 24)
        this.minutes = (Math.floor(timeSpentInSeconds / 60) % 60)
        this.seconds = (Math.floor(timeSpentInSeconds) % 60)

        if (this.gameState !== GameState.ENDED) {
          this.startTimer()
        }
      }
    },
    computed: {
      hoursString() {
        return this.hours.toString().padStart(2, '0')
      },
      minutesString() {
        return this.minutes.toString().padStart(2, '0')
      },
      secondsString() {
        return this.seconds.toString().padStart(2, '0')
      },
      isGameStopped: function() {
        return this.gameState === GameState.STOPPED
      },
      isGameStarted: function() {
        return this.gameState === GameState.STARTED
      },
      isGameEnded: function() {
        return this.gameState === GameState.ENDED
      }
    },
    methods: {
      startGame: function() {
        this.$emit('game:start', new Date())
        this.startTimer()
      },
      startTimer: function() {
        this.refreshInterval = setInterval(() => {
          if (this.seconds < 59) {
            ++this.seconds
            return
          }
          this.seconds = 0
          if (this.minutes < 59) {
            ++this.minutes
            return
          }
          this.minutes = 0
          ++this.hours
        }, 1000)
      },
      stopTimer: function() {
        clearInterval(this.refreshInterval)
      }
    },
    beforeDestroy() {
      this.stopTimer()
    }
  }
</script>
