import Vue from 'vue';
import Router from 'vue-router';
import store from '../store';

import Scenario from '../views/Scenario.vue';
import Game from '../views/Game.vue';
import Login from '../views/Login.vue';

Vue.use(Router);

class RouteMeta {
  title: string;

  constructor({title}: { title: string }) {
    this.title = title;
  }
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: new RouteMeta({ title: 'Login' })
    },
    {
      path: '/scenario',
      name: 'scenario',
      component: Scenario,
      meta: new RouteMeta({ title: 'Scénario' })
    },
    {
      path: '/game/:code',
      name: 'game',
      component: Game,
      meta: new RouteMeta({ title: 'Jeu' })
    },
    {
      path: '/new-game',
      name: 'new-game',
      component: Scenario,
      meta: new RouteMeta({ title: 'Nouveau jeu' })
    },
  ]
});

// This callback runs before every route change, including on initial load
router.beforeEach((to, from, next) => {

  const routeMeta = to.meta as RouteMeta;
  store.dispatch('topToolbar/changeTitle', routeMeta.title);
  next();
});

export default router;
