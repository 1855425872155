import {Scenario} from '../types';
import envConfig from '../../environment.config';

class ScenarioService {

  getScenari(): Promise<Scenario[]> {
    const url = envConfig.apiUrl + '/';
    return fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((serverGames) => {
        return serverGames.map(ScenarioService.map);
      })
      .catch((e) => {
        console.error('An error occurred retrieving the news articles from ' + url, e);
      })
  }
  
  getScenarioById(scenarioId: number): Scenario {
    return {
      id: 1,
      texteCourt: '',
      texteLong: `Depuis des millénaires, les terres du bois Pillot sont
            un havre de paix pour la nature. La légende raconte qu’ici les
            druides communient avec les dieux celtes afin de transmettre
            aux hommes tout le savoir de la faune et la flore pour que
            l’harmonie entre les dieux, les hommes, les animaux et les végétaux
            soit et perdure.
            <br/><br/>
            Malheureusement, certains dieux n’ont pas vocation à faire le bien.
            Elcmar est un dieu sombre, il est l’opposé de son frère Dagda, dieu
            des druides. Animé par la jalousie, Elcmar souhaite détruire l’harmonie
            créée par son frère. Pour mener à bien son plan, il a enlevé et enfermé
            Epona, déesse de la fertilité et de l’abondance, dans une cage scellée
            de rune divines afin de condamner la prospérité de ces lieux sacrés.
            Elcmar espère qu’ainsi, la communion entre les dieux et les druides s’éteigne.
            <br/>
            Pour faire renaître et perdurer l’harmonie entre tous, vous devez libérer Epona
            de sa cage en trouvant l’incantation divine. Les esprits des dieux vous
            aideront dans votre quête, ils ne peuvent vous parler que sous forme d’énigme.
            Suivez leur trace et résolvez leurs énigmes, ainsi, vous redonnerez vie au bois Pillot.`
    } as Scenario;
  }

  private static map(serverScenario: any): Scenario {
    return {
      id: serverScenario.id,
      texteCourt: serverScenario.texteCourt,
      texteLong: serverScenario.texteLong
    } as Scenario;
  }

}

export default new ScenarioService();
