<template>

  <div>
    <!-- Tabs -->
    <v-tabs
      dark
      centered
      fixed-tabs
      hide-slider
      v-model="activeRiddle"
      id="riddle-bar"
    >
      <v-tab v-for="riddle in riddles" :key="riddle.id">
        E{{ riddle.id }}
        <v-icon v-if="riddle.completed" color="green">mdi-medal</v-icon>
      </v-tab>
    </v-tabs>
    <!-- Tabs content -->
    <v-tabs-items v-model="activeRiddle">
      <v-tab-item v-for="riddle in riddles" :key="riddle.id">
        <Riddle :riddle="riddle"></Riddle>
        <RiddleResponseForm
          v-if="!isDruide && !riddle.completed"
          :gameId="gameId"
          :riddleId="riddle.id"
          v-on:response:correct="$emit('response:correct', {
            'correctResponse': $event,
            'riddleId': riddle.id
          })"
        ></RiddleResponseForm>
        <v-card
          v-if="riddle.completed || isDruide"
          tile
          id="bonne-reponse"
          class="text-center my-2"
          v-bind:class="{ 'completed': riddle.completed }"
        >
          <v-card-text>
            <h2>{{riddle.response}}</h2>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>

</template>

<script>
  import Riddle from '../components/Riddle'
  import RiddleResponseForm from '../components/RiddleResponseForm'
  import riddleService from '../services/riddleService'

  export default {
    props: {
      isDruide: Boolean,
      riddles: Array,
      gameId: Number
    },
    data: () => ({
      activeRiddle: 0,
    }),
    components: {
      Riddle,
      RiddleResponseForm
    }
  }
</script>

<style scoped>
  #riddle-bar .v-slide-group__prev--disabled,
  #riddle-bar .v-slide-group__next--disabled {
    display: none !important;
  }
  #riddle-bar .v-tab {
    min-width: unset !important;
    max-width: unset !important;
    width: 24% !important;
  }
  #riddle-bar .v-tab--active {
    border-bottom: 6px solid #a6a6a6;
  }
  #bonne-reponse h2 {
    color: firebrick;
  }
  #bonne-reponse.completed h2 {
    color: #237a00;
  }
</style>
