import { Riddle } from '../types';
import envConfig from '../../environment.config';

const axios = require('axios').default;

class RiddleService
{
  async getRiddles(gameId: number, userToken: string|null): Promise<Riddle[]>
  {
    return await axios.get(envConfig.apiUrl + 'riddles/' + gameId, {
      params: {
        token: userToken
      }
    })
      .then(response => response.data.riddles.map(RiddleService.map))
      .catch(() => []);
  }

  async isCorrectResponse(gameId: number, riddleId: number, response: string): Promise<boolean> {
    if (response == '') {
      return false;
    }
    return await axios.post(envConfig.apiUrl + 'riddle/validresponse', {
      gameId: gameId,
      riddleId: riddleId,
      response: response,
    })
      .then(response => response.data.isCorrectResponse)
      .catch(() => false);
  }
  
  private static map(serverRiddle: any): Riddle {
    return {
      id: serverRiddle.id,
      description: serverRiddle.description,
      completed: serverRiddle.completed,
      response: serverRiddle.response,
      clues: serverRiddle.clues,
    } as Riddle;
  }
}

export default new RiddleService();