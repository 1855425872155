<template>

    <div class="text-center">

        <h2>Entrer dans le jeu</h2>

        <div class="d-inline-flex">

            <v-text-field
                label="Code"
                v-model="code"
                :error="error"
            ></v-text-field>

            <v-btn icon @click="joinGame" class="v-btn-join-game">
                <v-icon x-large>mdi-chevron-right-box</v-icon>
            </v-btn>

        </div>

    </div>

</template>

<style scoped>
.v-btn-join-game {
  align-self: center;
}
</style>

<script>
  import gameService from '../services/gameService'
  
  export default {
    data: () => ({
      code: '',
      error: false
    }),
    methods: {
      joinGame() {
        let self = this;
        gameService.isCodeExists(this.code).then(isCodeExists => {
          if (isCodeExists) {
            self.$router.push({ path: '/game/' + self.code });
          }
          self.error = !isCodeExists;
          self.code = '';
        });
      }
    }
  }
</script>