import envConfig from '../../environment.config';

const axios = require('axios').default;

class AuthService
{
  async login(id: string, pass: string): Promise<String> {
    return await axios.post(envConfig.apiUrl + 'login', {
      login: id,
      password: pass
    })
      .then(response => response.data)
      .catch(() => '');
  }

  async isDruide(token: string): Promise<boolean> {
    return await axios.post(envConfig.apiUrl + 'isdruide', {
      token: token
    })
      .then((response) => {
        if (!response.data.isDruide) {
          localStorage.removeItem('token');
        }
        return response.data.isDruide;
      })
      .catch(() => false);
  }
}

export default new AuthService();
