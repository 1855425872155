import {Game} from '../types';
import envConfig from '../../environment.config';

const axios = require('axios').default;

class GameService
{
  async getGames(): Promise<Game[]> {
    return await axios.get(envConfig.apiUrl + 'games')
      .then(response => response.data.games.map(GameService.map))
      .catch(() => []);
  }

  async isCodeExists(code: number): Promise<boolean> {
    if (code == 0) {
      return false;
    }
    return await axios.post(envConfig.apiUrl + 'game/checkcode', {
      code: code
    })
      .then(response => response.data.isCodeExists)
      .catch(() => false);
  }
  
  async getGameByCode(code: number): Promise<Game>
  {
    return await axios.get(envConfig.apiUrl + 'game/' + code)
      .then(response => response.data.game as Game)
      .catch(() => {});
  }

  async updateTeamName(code: number, teamName: string): Promise<boolean>
  {
    return await axios.post(
      envConfig.apiUrl + 'update-team',
      {code, teamName}
    )
      .then(() => true)
      .catch(() => false)
  }

  async updateTeamPlayers(code: number, teamPlayers: number): Promise<boolean>
  {
    return await axios.post(
      envConfig.apiUrl + 'update-team',
      {code, teamPlayers}
    )
      .then(() => true)
      .catch(() => false)
  }

  async startGame(code: number, startAt: Date): Promise<boolean>
  {
    return await axios.post(
    envConfig.apiUrl + 'game/start',
    {code, startAt}
    )
      .then(() => true)
      .catch(() => false)
  }

  async stopGame(code: number, completeAt: Date): Promise<boolean>
  {
    return await axios.post(
    envConfig.apiUrl + 'game/stop',
    {code, completeAt}
    )
      .then(() => true)
      .catch(() => false)
  }

  async createGame(): Promise<boolean>
  {
    return await axios.post(envConfig.apiUrl + 'game/create')
      .then(() => true)
      .catch(() => false)
  }

  private static map(serverGame: any): Game {
    return {
      id: serverGame.id,
      code: serverGame.code,
      teamName: serverGame.teamName,
      teamPlayers: serverGame.teamPlayers,
      beginDate: serverGame.beginDate,
      completeDate: serverGame.completeDate,
      endDate: serverGame.endDate
    } as Game;
  }
}

export default new GameService();