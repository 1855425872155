<template>
    <v-progress-linear
      height="10"
      :value="completed"
      color="green"
    >
    </v-progress-linear>
</template>

<script>
  export default {
    props: {
      completed: Number,
    },
  }
</script>