<template>

  <v-app>

    <v-main class="text-center">

      <v-toolbar dense flat v-if="isHome">
        <v-spacer></v-spacer>
        <v-btn icon :to="{name: 'login'}">
          <v-icon>mdi-account-circle</v-icon>
        </v-btn>
      </v-toolbar>

      <v-layout v-if="isHome" column="false" align-center="true">
        <v-img src="/images/logo.png" alt="" class="logo my-3"></v-img>
      </v-layout>

      <p v-if="isHome" class="px-2">
        Depuis des siècles, le bois Pillot est un lieu sacré, où les dieux, les animaux et les hommes communient et vivent unis, plus que jamais.
        <br><br>
        Depuis ce jour, un danger pèse sur ces terres. Le dieu Elcmar est en colère et souhaite détruire l'harmonie qui s'y trouve. Aidez les druides à lui faire face et sauvez le bois Pillot !
      </p>

      <GameAccesserForm v-if="isHome"></GameAccesserForm>

      <router-view></router-view>

      <v-messenger></v-messenger>

    </v-main>

    <GameBottomNav v-if="!isHome && isDruide"></GameBottomNav>

    <v-footer
      app
      tile
      min-height="25"
      v-if="isGame && !isDruide"
    >
      <HelpButton :gameCode="gameCode"></HelpButton>
    </v-footer>

  </v-app>

</template>

<script>
import GameBottomNav from './components/GameBottomNav';
import GameAccesserForm from './components/GameAccesserForm';
import HelpButton from './components/HelpButton';
import authService from './services/authService';
import helpService from './services/helpService';
import envConfig from '../environment.config';

export default {
  data: () => ({
    isDruide: false,
    gameCode: '',
    druideInterval: null,
  }),
  mounted() {
    this.isDruideToken();
    this.setGameCode();
  },
  computed: {
    isHome() {
      return null === this.$route.name;
    },
    isGame() {
      return 'game' === this.$route.name;
    }
  },
  watch: {
    $route(to, from) {
      this.isDruideToken();
      this.setGameCode();
    },
    isDruide(value) {
      value
        ? this.druideInterval = setInterval(this.getNextDruideDemande, envConfig.checkDruideDemandeInterval)
        : clearInterval(this.druideInterval)
    }
  },
  methods: {
    setGameCode: function() {
      this.gameCode = this.$route.params.code || '';
    },
    isDruideToken: function() {
      let userToken = localStorage.getItem('token');
      if (userToken !== null) {
        let self = this;
        authService.isDruide(userToken).then(isDruide => {
          self.isDruide = isDruide;
        });
      }
      else {
        this.isDruide = false;
      }
    },
    getNextDruideDemande: function() {
      let self = this
      helpService.getNextDruideHelp().then(help => {
        if (help === null) {
          return
        }
        self.$messenger.showConfirmAlert(
          "Aide demandée pour " + (help.teamName || help.code),
          () => {
            console.log("Yes clicked")
          },
          () => {
            console.log("No clicked")
          }
        )
      })
    }
  },
  beforeDestroy() {
    clearInterval(this.druideInterval)
  },
  components: {
    GameBottomNav,
    GameAccesserForm,
    HelpButton
  }
}
</script>

<style>
  html,
  body,
  .v-toolbar {
    max-width: 720px !important;
    margin: 0 auto;
  }
  .v-application,
  .v-footer {
    background-color: #FFFFFF !important;
  }
</style>
<style scoped>
  .logo {
    width: 90%;
    max-width: 400px;
  }
</style>
