<template>

  <v-container fluid>
    <v-toolbar dense flat>
      <v-btn icon to="/">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </v-toolbar>

    <div class="px-4">
        <h1>Scénario</h1>
        <p class="text-justify" v-html="scenario.texteLong"></p>
    </div>
  </v-container>

</template>

<script>
  import scenarioService from '../services/scenarioService'
  import envConfig from '../../environment.config'
  
  export default {
    data: () => ({
      scenario: ''
    }),
    mounted() {
      this.scenario = scenarioService.getScenarioById(envConfig.scenarioId)
    }
  }
</script>
