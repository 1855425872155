<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="290"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        fab
        icon
        absolute
        outlined
        elevation="2"
        color="deep-orange darken-4"
        class="btn-call-druide"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon size="56px">person</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h5">
        Appel au druide
      </v-card-title>
      <v-card-text>Voulez-vous demander l'aide du druide ?</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="green darken-1"
          text
          @click="dialog = false"
        >
          Non
        </v-btn>
        <v-btn
          color="green darken-1"
          text
          @click="askForDruide"
        >
          Oui
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import helpService from '../services/helpService';

  export default {
    name: "HelpButton",
    props: {
      gameCode: String,
    },
    data: () => ({
      dialog: false,
    }),
    methods: {
      askForDruide() {
        this.dialog = false;
        helpService.askForDruide(this.gameCode).then(asked => {
          asked
            ? this.$messenger.showSuccessSnackbar('Le druide est en chemin')
            : this.$messenger.showErrorSnackbar("Le druide n'a pas pu être contacté, veuillez réessayer")
        });
      }
    }
  }
</script>

<style scoped>
  .btn-call-druide {
    bottom: 25px;
    right: 20px;
  }
</style>